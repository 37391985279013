import {useReportsContext} from '../../context/reports-context';
import Layout from '../Layout';
import ReportingPeriodPicker from '../forms/ReportingPeriodPicker';
import {ReactNode} from 'react';

interface ReportsLayoutProps {
	title?: ReactNode;
	children: ReactNode;
}

const ReportsLayout = ({title, children}: ReportsLayoutProps) => {
	const {reportingPeriod} = useReportsContext();

	return (
		<Layout>
			<Layout.Header title={title}>
				<ReportingPeriodPicker
					startDate={reportingPeriod.startDate}
					endDate={reportingPeriod.endDate}
					onChange={reportingPeriod.onChange}
				/>
			</Layout.Header>
			<Layout.Body>{children}</Layout.Body>
		</Layout>
	);
};

export default ReportsLayout;
