import {useIsMobile} from '../util/mobile';
import {List} from './List';
import Table from './Table';
import {Box} from '@sproutsocial/racine';
import {type ReactElement, memo} from 'react';

interface MobileFriendlyItemDisplayProps {
	list: ReactElement<typeof List>;
	table: ReactElement<typeof Table>;
	[key: string]: any;
}

const MobileFriendlyItemDisplay = memo(({list, table, ...rest}: MobileFriendlyItemDisplayProps) => {
	const isMobile = useIsMobile();

	return <Box {...rest}>{isMobile ? list : table}</Box>;
});

export default MobileFriendlyItemDisplay;
