export type TagColor =
	| 'red.500'
	| 'blue.500'
	| 'yellow.500'
	| 'purple.500'
	| 'green.500'
	| 'orange.500'
	| 'pink.500'
	| 'magenta.500'
	| 'neutral.500'
	| 'teal.500'
	| 'aqua.500';

export const TAG_COLORS: Array<{name: string; value: TagColor}> = [
	{name: 'Red', value: 'red.500'},
	{name: 'Blue', value: 'blue.500'},
	{name: 'Yellow', value: 'yellow.500'},
	{name: 'Purple', value: 'purple.500'},
	{name: 'Green', value: 'green.500'},
	{name: 'Orange', value: 'orange.500'},
	{name: 'Pink', value: 'pink.500'},
	{name: 'Magenta', value: 'magenta.500'},
	{name: 'Gray', value: 'neutral.500'},
	{name: 'Teal', value: 'teal.500'},
	{name: 'Aqua', value: 'aqua.500'},
];
