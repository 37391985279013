import {Input, type TypeInputProps} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useEffect, useId, useRef, useState} from 'react';

type MoneyInputProps = Omit<TypeInputProps, 'id' | 'name' | 'value' | 'onChange'> & {
	id?: string;
	name?: string;
	value: number | null | undefined;
	onChange: (pennies: number | null) => void;
};

const MoneyInput = ({id, name, value, onChange, ...rest}: MoneyInputProps) => {
	const generatedId = useId();

	const isEditingRef = useRef(false);
	const [localValue, setLocalValue] = useState(
		typeof value === 'number' ? (value / 100).toFixed(2) : '',
	);

	useEffect(() => {
		if (!isEditingRef.current) {
			setLocalValue(typeof value === 'number' ? (value / 100).toFixed(2) : '');
		}
	}, [value, isEditingRef]);

	const onInputChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
		isEditingRef.current = true;

		setLocalValue(event.currentTarget.value);
	}, []);

	const onInputBlur = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			isEditingRef.current = false;

			if (event.currentTarget.value === '') {
				onChange(null);

				return;
			}

			const potentialValue = parseFloat(event.currentTarget.value);

			if (!isNaN(potentialValue)) {
				onChange(Math.floor(potentialValue * 100));
			}
		},
		[onChange],
	);

	return (
		<Input
			{...rest}
			id={id ?? generatedId}
			name={name ?? generatedId}
			type='number'
			value={localValue}
			onChange={onInputChange}
			onBlur={onInputBlur}
			elemBefore='$'
			inputProps={{
				min: 0,
			}}
		/>
	);
};

export default MoneyInput;
