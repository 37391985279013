import {formatFileSize} from '../../formatters';
import {gql} from '../../graphql';
import {useIsMobile} from '../../util/mobile';
import {List} from '../List';
import MobileFriendlyItemDisplay from '../MobileFriendlyItemDisplay';
import Table from '../Table';
import {useQuery} from '@apollo/client';
import {Box, Button, Icon, Link, Text, toast} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useMemo} from 'react';
import {canPreviewFile, getDisplayType, getFileInputAccept} from 'shared/src/config.js';
import {orderBy} from 'shared/src/list';
import styled from 'styled-components';

interface ProjectFilesProps {
	projectId: number;
}

const GET_PROJECT = gql(/* GraphQL */ `
	query GetProjectFiles($id: Int!) {
		project(id: $id) {
			id
			files {
				id
				name
				description
				mimeType
				size
			}
		}
	}
`);

const ProjectFiles = ({projectId}: ProjectFilesProps) => {
	const isMobile = useIsMobile();

	const {data: {project} = {}, refetch: refetchProject} = useQuery(GET_PROJECT, {
		variables: {
			id: projectId,
		},
	});

	const files = useMemo(() => {
		return project ? orderBy(project.files, 'name') : null;
	}, [project]);

	const onFileSelected = useCallback(
		async (event: SyntheticEvent<HTMLInputElement>) => {
			const formData = new FormData();
			formData.append('description', '');
			formData.append('projectId', String(projectId));
			formData.append('file', event.currentTarget.files![0]);

			console.info(event.currentTarget.files![0]);

			const response = await fetch('/api/file/upload', {
				method: 'POST',
				body: formData,
			});

			if (response.ok) {
				await refetchProject();
			} else {
				toast({
					theme: 'error',
					content: 'File upload failed',
				});
			}
		},
		[projectId, refetchProject],
	);

	if (!project || !files) {
		return null;
	}

	return (
		<>
			<Box display='flex' justifyContent='flex-end' mb='space.400' pr={isMobile ? 'space.300' : 0}>
				<StyledLabel htmlFor='file-upload'>
					<Button appearance='primary' ml='space.300'>
						<Icon name='plus' mr='space.300' />
						Upload new file
					</Button>
					<input
						id='file-upload'
						type='file'
						onChange={onFileSelected}
						accept={getFileInputAccept()}
					/>
				</StyledLabel>
			</Box>

			<MobileFriendlyItemDisplay
				list={
					<List id={`project-${projectId}-files`}>
						{files.map((file) => (
							<List.Item
								key={file.id}
								title={file.name}
								description={file.description}
								data={getDisplayType(file.mimeType, file.name)}
								subdata={formatFileSize(file.size)}
							/>
						))}
					</List>
				}
				table={
					<Table
						id={`project-${projectId}-files`}
						head={[
							{id: 'name', content: 'Name'},
							{id: 'description', content: 'Description'},
							{id: 'type', content: 'Type'},
							{id: 'size', content: 'Size'},
							{id: 'view', content: ''},
							{id: 'download', content: ''},
						]}
						items={files}
						generateRow={(file) => ({
							id: file.id,
							cells: [
								file.name,
								file.description,
								getDisplayType(file.mimeType, file.name),
								formatFileSize(file.size),
								canPreviewFile(file.mimeType, file.name) && (
									<Link href={`/api/file/${file.id}`} external>
										<Icon name='eye' />
									</Link>
								),
								<Link href={`/api/file/${file.id}?download`} external>
									<Icon name='arrow-down-to-bracket-solid' />
								</Link>,
							],
						})}
					/>
				}
			/>

			{project.files.length === 0 && (
				<Box display='flex' justifyContent='center' mt='space.400'>
					<Text color='text.subtext' fontSize='200' fontWeight='semibold'>
						No files yet
					</Text>
				</Box>
			)}
		</>
	);
};

const StyledLabel = styled.label`
	display: flex;
	align-content: center;
	cursor: pointer;

	button {
		pointer-events: none;
	}

	input {
		display: none;
	}
`;

export default ProjectFiles;
