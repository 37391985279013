import TagName from './TagName';
import {Box, Token, Tooltip} from '@sproutsocial/racine';
import {type TypeTokenProps} from '@sproutsocial/racine/dist/types/Token/TokenTypes';
import {SyntheticEvent, useCallback} from 'react';

interface TagTokenProps extends Omit<TypeTokenProps, 'id' | 'children' | 'onClick'> {
	id: number;
	includeParents?: boolean;
	onClick?: (event: SyntheticEvent<HTMLButtonElement>, tagId: number) => void;
}

const TagToken = ({id, includeParents = false, onClick, ...rest}: TagTokenProps) => {
	const handleClick = useCallback(
		(event: SyntheticEvent<HTMLButtonElement>) => {
			onClick?.(event, id);
		},
		[id, onClick],
	);

	return (
		<Tooltip content={id}>
			<Token onClick={onClick ? handleClick : undefined} {...rest}>
				<Box display='flex' alignItems='center'>
					<TagName id={id} includeParents={includeParents} showIcon />
				</Box>
			</Token>
		</Tooltip>
	);
};

export default TagToken;
