import {useReportsContext} from '../../context/reports-context';
import {
	useIncomeTransactions,
	useSalesTaxCollectedTransactions,
} from '../../graphql/transaction-hooks';
import TransactionsTable from '../transactions/TransactionsTable';
import ReportsLayout from './ReportsLayout';

const SalesTaxReport = () => {
	const {reportingPeriod} = useReportsContext();

	const {transactions: salesTaxTransactions, refetch: refetchSalesTaxTransactions} =
		useSalesTaxCollectedTransactions({
			startDate: reportingPeriod.startDate,
			endDate: reportingPeriod.endDate,
		});

	const {transactions: incomeTransactions, refetch: refetchIncomeTransactions} =
		useIncomeTransactions({
			startDate: reportingPeriod.startDate,
			endDate: reportingPeriod.endDate,
		});

	return (
		<ReportsLayout title='Sales Tax Report'>
			<TransactionsTable
				id='sales-tax-report-taxes'
				title='Sales Tax Collected'
				transactions={salesTaxTransactions ?? []}
				onChange={refetchSalesTaxTransactions}
				mb='space.400'
			/>
			<TransactionsTable
				id='sales-tax-report-income'
				title='Total Income'
				transactions={incomeTransactions ?? []}
				onChange={refetchIncomeTransactions}
			/>
		</ReportsLayout>
	);
};

export default SalesTaxReport;
