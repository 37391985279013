import {Tag} from '../../graphql';
import {useTags} from '../../graphql/tag-hooks';
import {Icon} from '@sproutsocial/racine';
import {useMemo} from 'react';
import {keyBy} from 'shared/src/map';

interface TagNameProps {
	id: number;
	includeParents?: boolean;
	showIcon?: boolean;
}

const TagName = ({id, includeParents = false, showIcon = false}: TagNameProps) => {
	const {tags} = useTags();
	const tagMap = tags && keyBy(tags, (tag) => tag.id);
	const tag = tagMap?.get(id);

	const fullTagName = useMemo(() => {
		if (!tag || !tagMap) {
			return '';
		}

		if (!includeParents) {
			return tag.name;
		}

		const getTagName = (tag: Tag): string => {
			const parentTag = tag.parentTagId ? tagMap.get(tag.parentTagId) : null;
			const parentTagName = parentTag ? getTagName(parentTag) : null;

			return parentTagName ? `${parentTagName} > ${tag.name}` : tag.name;
		};

		return getTagName(tag);
	}, [includeParents, tag, tagMap]);

	return (
		<>
			{showIcon && tag && <Icon name='tag' color={tag.color} mr='space.300' />}
			{fullTagName}
		</>
	);
};

export default TagName;
