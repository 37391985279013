import {
	Box,
	Button,
	DateRangePicker,
	Icon,
	Input,
	Popout,
	Select,
	type TypeSpaceSystemProps,
} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import moment, {type Moment} from 'moment';
import {ReactNode, SyntheticEvent, useCallback, useMemo, useRef, useState} from 'react';
import 'react-dates/lib/css/_datepicker.css';
import {getNameForDateRange} from 'shared/src/datetime';

type ReportingPeriodPickerProps = TypeSpaceSystemProps & {
	startDate: DateTime;
	endDate: DateTime;
	onChange: (startDate: DateTime, endDate: DateTime) => void;
};

enum PresetRangeIds {
	THIS_WEEK,
	LAST_WEEK,
	THIS_MONTH,
	LAST_MONTH,
	THIS_YEAR,
	LAST_YEAR,
}

type PresetRange = (typeof PRESET_RANGES)[0];

const PRESET_RANGES = [
	{
		id: PresetRangeIds.THIS_WEEK,
		name: 'This Week',
		startDate: DateTime.now().startOf('week', {useLocaleWeeks: true}),
		endDate: DateTime.now().endOf('week', {useLocaleWeeks: true}),
	},
	{
		id: PresetRangeIds.LAST_WEEK,
		name: 'Last Week',
		startDate: DateTime.now().minus({week: 1}).startOf('week', {useLocaleWeeks: true}),
		endDate: DateTime.now().minus({week: 1}).endOf('week', {useLocaleWeeks: true}),
	},
	{
		id: PresetRangeIds.THIS_MONTH,
		name: 'This Month',
		startDate: DateTime.now().startOf('month'),
		endDate: DateTime.now().endOf('month'),
	},
	{
		id: PresetRangeIds.LAST_MONTH,
		name: 'Last Month',
		startDate: DateTime.now().minus({month: 1}).startOf('month'),
		endDate: DateTime.now().minus({month: 1}).endOf('month'),
	},
	{
		id: PresetRangeIds.THIS_YEAR,
		name: 'This Year',
		startDate: DateTime.now().startOf('year'),
		endDate: DateTime.now().endOf('year'),
	},
	{
		id: PresetRangeIds.LAST_YEAR,
		name: 'Last Year',
		startDate: DateTime.now().minus({year: 1}).startOf('year'),
		endDate: DateTime.now().minus({year: 1}).endOf('year'),
	},
];

const ReportingPeriodPicker = ({
	startDate,
	endDate,
	onChange,
	...rest
}: ReportingPeriodPickerProps) => {
	const [isPickingCustomRange, setIsPickingCustomRange] = useState<boolean>(false);
	const [datePickerFocusedRange, setDatePickerFocusedRange] = useState<'startDate' | 'endDate'>(
		'startDate',
	);
	const [datePickerStartMoment, setDatePickerStartMoment] = useState<Moment | null>(null);
	const [datePickerEndMoment, setDatePickerEndMoment] = useState<Moment | null>(null);

	const startDateInputRef = useRef<HTMLInputElement | null>(null);
	const endDateInputRef = useRef<HTMLInputElement | null>(null);

	const dateRangeType = useMemo<PresetRangeIds | 'custom'>(() => {
		// If we're in the state of picking a custom range, we override the passed props
		if (isPickingCustomRange) {
			return 'custom';
		}

		const presetRange = getPresetDateRangeFromDates(startDate, endDate);

		return presetRange?.id ?? 'custom';
	}, [startDate, endDate, isPickingCustomRange]);

	const onDateRangeTypeChanged = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			const id = event.currentTarget.value;

			if (id === 'custom') {
				setIsPickingCustomRange(true);
				setDatePickerFocusedRange('startDate');
				setDatePickerStartMoment(moment(startDate.toISO()));
				setDatePickerEndMoment(null);

				startDateInputRef.current?.focus();
			} else {
				setIsPickingCustomRange(false);
				setDatePickerFocusedRange('startDate');
				setDatePickerStartMoment(null);
				setDatePickerEndMoment(null);

				const range = PRESET_RANGES.find((range) => range.id.toString() === id)!;

				onChange(range.startDate, range.endDate);
			}
		},
		[startDate, onChange],
	);

	const onDatesChanged = useCallback(
		({
			startDate: startMoment,
			endDate: endMoment,
		}: {
			startDate: Moment | null;
			endDate: Moment | null;
		}) => {
			if (datePickerFocusedRange === 'startDate') {
				setIsPickingCustomRange(true);
				setDatePickerFocusedRange('endDate');
				setDatePickerStartMoment(startMoment);
				setDatePickerEndMoment(null);

				endDateInputRef.current?.focus();
			} else if (startMoment && endMoment) {
				setIsPickingCustomRange(false);
				setDatePickerFocusedRange('startDate');
				setDatePickerStartMoment(null);
				setDatePickerEndMoment(null);

				startDateInputRef.current?.focus();

				onChange(
					DateTime.fromISO(startMoment.toISOString()),
					DateTime.fromISO(endMoment.toISOString()),
				);
			}
		},
		[datePickerFocusedRange, onChange],
	);

	const onStartInputFocused = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
		setDatePickerFocusedRange('startDate');
	}, []);

	const onEndInputFocused = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			setIsPickingCustomRange(true);
			setDatePickerFocusedRange('endDate');

			if (!datePickerStartMoment) {
				setDatePickerStartMoment((startMoment) => {
					return startMoment ?? moment(startDate.toISO());
				});
			}

			setDatePickerEndMoment(null);
		},
		[startDate, datePickerStartMoment],
	);

	const buttonLabel = useMemo<ReactNode>(
		() => getNameForDateRange(startDate, endDate),
		[startDate, endDate],
	);

	return (
		<Popout
			content={
				<Box
					display='flex'
					border='500'
					borderRadius='outer'
					borderColor='container.border.base'
					bg='white'
					boxShadow='high'
				>
					<Box py='space.400'>
						<DateRangePicker
							startDate={datePickerStartMoment ?? moment(startDate.toISO())}
							endDate={isPickingCustomRange ? datePickerEndMoment : moment(endDate.toISO())}
							focusedInput={datePickerFocusedRange}
							onFocusChange={() => {}}
							onDatesChange={onDatesChanged}
						/>
					</Box>
					<Box
						display='flex'
						flexDirection='column'
						rowGap='space.300'
						width={250}
						borderLeft='500'
						borderColor='container.border.base'
						p='space.400'
					>
						<Select
							id='reporting-period-type'
							name='reporting-period-type'
							value={dateRangeType}
							onChange={onDateRangeTypeChanged}
							inputProps={{tabIndex: 0}}
						>
							{PRESET_RANGES.map((range) => (
								<option key={range.id} value={range.id}>
									{range.name}
								</option>
							))}

							<option value='custom'>Custom Range</option>
						</Select>
						<Box display='flex' alignItems='center' justifyContent='space-between'>
							<Input
								id='date-range-picker-start-date'
								name='date-range-picker-start-date'
								value={datePickerStartMoment?.format('M/D/yyyy') ?? startDate.toFormat('M/d/yyyy')}
								innerRef={startDateInputRef}
								onFocus={onStartInputFocused}
								inputProps={{tabIndex: 1, autoFocus: true}}
							/>

							<Icon name='arrow-right' size='small' mx='space.300' />

							<Input
								id='date-range-picker-end-date'
								name='date-range-picker-end-date'
								value={
									isPickingCustomRange
										? datePickerEndMoment
											? datePickerEndMoment.format('M/D/yyyy')
											: 'mm/dd/yyyy'
										: endDate.toFormat('M/d/yyyy')
								}
								innerRef={endDateInputRef}
								onFocus={onEndInputFocused}
								inputProps={{tabIndex: 0}}
							/>
						</Box>
					</Box>
				</Box>
			}
		>
			<Button appearance='secondary' {...rest}>
				<Icon name='calendar-range' mr='space.300' />
				{buttonLabel}
			</Button>
		</Popout>
	);
};

const getPresetDateRangeFromDates = (
	startDate: DateTime,
	endDate: DateTime,
): PresetRange | null => {
	return (
		PRESET_RANGES.find((range) => {
			return startDate.hasSame(range.startDate, 'day') && endDate.hasSame(range.endDate, 'day');
		}) ?? null
	);
};

export default ReportingPeriodPicker;
