import {formatCurrency} from '../../formatters';
import {type Invoice, type InvoiceLineItem} from '../../graphql';
import {getInvoiceAmountDue, getInvoiceTotal} from '../../invoice-helpers';
import {List} from '../List';
import MobileFriendlyItemDisplay from '../MobileFriendlyItemDisplay';
import Table from '../Table';
import DollarAmount from '../visual/DollarAmount';
import {Button, Icon, Link, Text} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import React from 'react';

type MinimumInvoice = Omit<Invoice, 'customer' | 'description' | 'items'> & {
	customer?: {
		id: string;
		name: string;
	};
	items: Array<Omit<InvoiceLineItem, 'id' | 'name'>>;
};

interface InvoiceTableProps {
	invoices: MinimumInvoice[];
	showCustomerName?: boolean;
}

const InvoiceTable = ({invoices, showCustomerName = true}: InvoiceTableProps) => {
	return (
		<MobileFriendlyItemDisplay
			list={
				<List id='all-invoices'>
					{invoices.map((invoice: MinimumInvoice) => {
						const amountDue = getInvoiceAmountDue(invoice.items);

						return (
							<List.Item
								key={invoice.id}
								title={invoice.customer?.name}
								description={DateTime.fromISO(invoice.date).toLocaleString(DateTime.DATE_MED)}
								data={formatCurrency(getInvoiceTotal(invoice.items))}
								subdata={
									<Text fontSize='200' color={amountDue === 0 ? 'green.800' : 'red.800'}>
										{amountDue === 0 ? 'PAID' : formatCurrency(amountDue)}
									</Text>
								}
							/>
						);
					})}
				</List>
			}
			table={
				<Table
					id='all-invoices'
					head={[
						{id: 'id', content: 'ID'},
						{id: 'date', content: 'Date'},
						{id: 'cost', content: 'Cost'},
						{id: 'due', content: 'Due'},
						...(showCustomerName ? [{id: 'customer', content: 'Customer Name'}] : []),
						{id: 'edit', content: ''},
						{id: 'preview', content: ''},
						{id: 'download', content: ''},
					]}
					items={invoices}
					generateRow={(invoice) => {
						const amountDue = getInvoiceAmountDue(invoice.items);

						return {
							id: String(invoice.id),
							cells: [
								<Link href={`/preview/${invoice.id}`} external>
									{invoice.id.toString().padStart(4, '0')}
								</Link>,
								DateTime.fromISO(invoice.date).toLocaleString(DateTime.DATE_MED),
								formatCurrency(getInvoiceTotal(invoice.items)),
								<DollarAmount fontWeight='semibold' value={amountDue} isBalanceDue />,
								...(showCustomerName
									? [
											<Link href={`/customers/${invoice.customer!.id}/invoices`}>
												{invoice.customer!.name}
											</Link>,
										]
									: []),
								<Link href={`/invoices/${invoice.id}/edit`}>
									<Icon name='pencil' />
								</Link>,
								<Link href={`/preview/${invoice.id}`} external>
									<Icon name='eye' />
								</Link>,
								<form
									method='POST'
									action={`/api/invoices/pdf/${invoice.id}`}
									style={{display: 'inline'}}
								>
									<Button
										// @ts-ignore
										type='submit'
										p={0}
									>
										<Icon
											name='arrow-down-to-bracket-solid'
											color='button.primary.background.base'
										/>
									</Button>
								</form>,
							],
						};
					}}
				/>
			}
		/>
	);
};

export default InvoiceTable;
