import {Box} from '@sproutsocial/racine';
import {type ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled(Box)`
	break-after: page;
	width: 835px;
	height: 1080px;
	padding: 49px;
`;

const PDFPage = ({children}: {children: ReactNode}) => {
	return (
		<Container>
			<Box width='100%' height='100%' overflow='hidden'>
				{children}
			</Box>
		</Container>
	);
};

export default PDFPage;
