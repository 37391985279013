import {Box, Text, type TypeBoxProps} from '@sproutsocial/racine';
import {type ReactNode} from 'react';
import styled from 'styled-components';

interface ListProps {
	id: string;
	children: ReactNode;
}

export function List(props: ListProps) {
	return (
		<Box display='flex' flexDirection='column'>
			{props.children}
		</Box>
	);
}

type ListItemProps = Omit<TypeBoxProps, 'title'> & {
	title?: string | ReactNode;
	description?: string | ReactNode;
	data?: string | ReactNode;
	subdata?: string | ReactNode;
};

List.Item = ({title = '', description, data, subdata, ...rest}: ListItemProps) => {
	let titleHtml: ReactNode = title;
	if (typeof titleHtml === 'string') {
		titleHtml = (
			<Text fontSize='200' fontWeight='semibold' color='text.header' truncated>
				{title}
			</Text>
		);
	}

	let descriptionHtml: ReactNode = description;
	if (typeof descriptionHtml === 'string') {
		descriptionHtml = (
			<Text fontSize='200' color='text.subtext' truncated>
				{description}
			</Text>
		);
	} else if (!descriptionHtml) {
		descriptionHtml = null;
	}

	let dataHtml: ReactNode = data;
	if (typeof dataHtml === 'string') {
		dataHtml = <Text fontSize='200'>{data}</Text>;
	} else if (!dataHtml) {
		dataHtml = <Text fontSize='200'>&nbsp;</Text>;
	}

	let subdataHtml: ReactNode = subdata;
	if (typeof subdataHtml === 'string') {
		subdataHtml = (
			<Text fontSize='200' color='text.subtext'>
				{subdata}
			</Text>
		);
	} else if (!subdataHtml) {
		subdataHtml = null;
	}

	return (
		<ListItemContainer {...rest} display='flex' justifyContent='space-between'>
			<Box display='flex' flexDirection='column' width='50%'>
				{titleHtml}
				{descriptionHtml}
			</Box>
			<Box display='flex' flexDirection='column' alignItems='flex-end' width='50%'>
				{dataHtml}
				{subdataHtml}
			</Box>
		</ListItemContainer>
	);
};

const ListItemContainer = styled(Box)`
	background: white;
	border-top: ${({theme}) => theme.borders['500']};
	border-color: ${({theme}) => theme.colors.container.border.base};
	padding: ${({theme}) => theme.space['300']};

	&:first-of-type {
		border-top: 0;
	}

	&:last-of-type {
		border-bottom: ${({theme}) => theme.borders['500']};
		border-color: ${({theme}) => theme.colors.container.border.base};
	}
`;
