import GlobalStyles from './components/GlobalStyles';
import {apolloClient} from './graphql';
import {initializeApp} from './init';
import router from './router';
import {ApolloProvider} from '@apollo/client';
import {Loader, ThemeProvider, ToastContainer} from '@sproutsocial/racine';
import {
	ExternalSprite as ExternalIconsHtml,
	GeneralSprite as GeneralIconsHtml,
	SproutSprite as SproutIconsHtml,
} from '@sproutsocial/seeds-icons';
import {Sprite as IllustrationsHtml} from '@sproutsocial/seeds-illustrations';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from 'react-router';

await initializeApp();

const root = createRoot(document.getElementById('root')!);

root.render(
	<React.StrictMode>
		<ThemeProvider>
			<ApolloProvider client={apolloClient}>
				<GlobalStyles />

				<div style={{display: 'none'}}>
					<div dangerouslySetInnerHTML={{__html: ExternalIconsHtml}} />
					<div dangerouslySetInnerHTML={{__html: GeneralIconsHtml}} />
					<div dangerouslySetInnerHTML={{__html: SproutIconsHtml}} />
					<div dangerouslySetInnerHTML={{__html: IllustrationsHtml}} />
				</div>

				<RouterProvider router={router} fallbackElement={<Loader delay />} />

				<ToastContainer />
			</ApolloProvider>
		</ThemeProvider>
	</React.StrictMode>,
);
