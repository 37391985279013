import {debounce} from './util/function';
import {DateTime} from 'luxon';
import {SyntheticEvent, useCallback, useMemo, useState} from 'react';

interface LocalStorageStateConfig<T> {
	localStorageKey: string;
	defaultValue: T;
	serialize?: (value: T) => string | null;
	deserialize?: (json: string) => T;
}

// All config values should be constant.
export const useLocalStorageState = <T>({
	localStorageKey,
	defaultValue,
	serialize = JSON.stringify,
	deserialize = JSON.parse,
}: LocalStorageStateConfig<T>): [T, (value: T) => void] => {
	const calculatedInitialValue = useMemo<T>(() => {
		const storedJson = localStorage.getItem(localStorageKey);

		return storedJson ? deserialize(storedJson) : defaultValue;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [value, rawSetValue] = useState(calculatedInitialValue);

	const setValue = useCallback(
		(newValue: T) => {
			rawSetValue(newValue);

			const serializedValue = serialize(newValue);

			if (serializedValue) {
				localStorage.setItem(localStorageKey, serializedValue);
			}
		},
		[localStorageKey, serialize, rawSetValue],
	);

	return [value, setValue];
};

export const useSetState = <V>(initialValues?: V[] | Set<V>): Set<V> => {
	const [set] = useState(new Set<V>(Array.from(initialValues ?? [])));

	const [sequence, setSequence] = useState(0);
	const triggerUpdate = useCallback(() => {
		setSequence(sequence + 1);
	}, [sequence, setSequence]);

	return useMemo<Set<V>>(() => {
		return new Proxy(set, {
			get(target, prop, proxy) {
				const originalValue = Reflect.get(target, prop, target);

				if (prop === 'add' && typeof originalValue === 'function') {
					return (value: V): Set<V> => {
						set.add(value);

						triggerUpdate();

						return set;
					};
				} else if (prop === 'clear' && typeof originalValue === 'function') {
					return (): Set<V> => {
						set.clear();

						triggerUpdate();

						return set;
					};
				} else if (prop === 'delete' && typeof originalValue === 'function') {
					return (key: V): Set<V> => {
						set.delete(key);

						triggerUpdate();

						return set;
					};
				}

				if (typeof originalValue === 'function') {
					return originalValue.bind(set);
				} else {
					return originalValue;
				}
			},
		});
	}, [set, triggerUpdate]);
};

export const useKeyValueState = <K, V>(initializer?: () => Map<K, V>): Map<K, V> => {
	const [map] = useState(initializer ?? new Map<K, V>());

	const [sequence, setSequence] = useState(0);
	const triggerUpdate = useCallback(() => {
		setSequence(sequence + 1);
	}, [sequence, setSequence]);

	return useMemo<Map<K, V>>(() => {
		return new Proxy(map, {
			get(target, prop, proxy) {
				const originalValue = Reflect.get(target, prop, target);

				if (prop === 'set' && typeof originalValue === 'function') {
					return (key: K, value: V): Map<K, V> => {
						map.set(key, value);

						triggerUpdate();

						return map;
					};
				} else if (prop === 'delete' && typeof originalValue === 'function') {
					return (key: K): Map<K, V> => {
						map.delete(key);

						triggerUpdate();

						return map;
					};
				}

				if (typeof originalValue === 'function') {
					return originalValue.bind(map);
				} else {
					return originalValue;
				}
			},
		});
	}, [map, triggerUpdate]);
};

// Can pass the mutation function directly to Input or TextArea as the `onChange` prop.
type TextInputOnChange = (
	event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement> | null,
	newValue?: string,
) => void;
export const useTextInputState = (initialValue: string = ''): [string, TextInputOnChange] => {
	const [value, setValue] = useState<string>(initialValue);

	const onChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement> | null, newValue?: string) => {
			setValue(newValue ?? event?.currentTarget.value ?? '');
		},
		[],
	);

	return [value, onChange];
};

// Can pass the mutation function directly to DatePicker
type DateInputOnChange = (updatedDateTime: DateTime) => void;
export const useDateInputState = (
	initialDate: DateTime = DateTime.now(),
): [DateTime, DateInputOnChange] => {
	const [value, setValue] = useState<DateTime>(initialDate);

	const onChange = useCallback((updatedDateTime: DateTime) => {
		setValue(updatedDateTime);
	}, []);

	return [value, onChange];
};

export const useDebouncedCallback = <T extends Function>(
	fn: T,
	delay: number,
	dependencies: any[],
): T => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useCallback(debounce(fn, delay) as any, dependencies);
};
