import {InvoiceLineItemType} from '../../__generated__/graphql';
import {InvoiceLineItem} from '../../models';
import FocusInput from '../FocusInput';
import {Box, Select, Switch} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useId, useRef} from 'react';

interface InvoiceLineItemEditFormProps {
	item: InvoiceLineItem;
	onChange: (updatedLineItem: InvoiceLineItem) => void;
}

const InvoiceLineItemEditForm = ({item, onChange}: InvoiceLineItemEditFormProps) => {
	const nameId = useId();
	const descriptionId = useId();
	const quantityId = useId();
	const costId = useId();
	const typeId = useId();

	const currentItemRef = useRef<InvoiceLineItem>();
	currentItemRef.current = item;

	const onNameUpdated = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const updatedLineItem = currentItemRef.current!.clone();
			updatedLineItem.name = event.currentTarget.value;

			onChange(updatedLineItem);
		},
		[onChange],
	);

	const onDescriptionUpdated = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const updatedLineItem = currentItemRef.current!.clone();
			updatedLineItem.description = event.currentTarget.value;

			onChange(updatedLineItem);
		},
		[onChange],
	);

	const onQuantityUpdated = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const updatedLineItem = currentItemRef.current!.clone();

			const {value} = event.currentTarget;
			updatedLineItem.quantity = value ? parseFloat(value) : undefined;

			onChange(updatedLineItem);
		},
		[onChange],
	);

	const onPriceUpdated = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const updatedLineItem = currentItemRef.current!.clone();
			const {value} = event.currentTarget;
			updatedLineItem.price = value ? Math.floor(parseFloat(value) * 100) : 0;

			onChange(updatedLineItem);
		},
		[onChange],
	);

	const onTypeChanged = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			const updatedLineItem = currentItemRef.current!.clone();

			updatedLineItem.type = event.currentTarget.value as InvoiceLineItemType;

			if (updatedLineItem.type !== InvoiceLineItemType.Cost) {
				updatedLineItem.taxed = false;
			}

			onChange(updatedLineItem);
		},
		[onChange],
	);

	const onTaxedToggled = useCallback(
		(event: SyntheticEvent<HTMLButtonElement>, checked: boolean) => {
			const updatedLineItem = currentItemRef.current!.clone();

			updatedLineItem.taxed = checked;

			onChange(updatedLineItem);
		},
		[onChange],
	);

	return (
		<Box display='flex' alignItems='center' mb='space.300'>
			<Box flex={3} mr='space.300'>
				<FocusInput id={nameId} name={nameId} value={item.name} onChange={onNameUpdated} />
			</Box>
			<Box flex={3} mr='space.300'>
				<FocusInput
					id={descriptionId}
					name={descriptionId}
					value={item.description ?? ''}
					onChange={onDescriptionUpdated}
				/>
			</Box>
			<Box flex={1} mr='space.300'>
				<FocusInput
					id={quantityId}
					name={quantityId}
					type='number'
					value={typeof item.quantity === 'number' ? item.quantity.toString() : ''}
					onChange={onQuantityUpdated}
				/>
			</Box>
			<Box flex={1} mr='space.300'>
				<FocusInput
					id={costId}
					name={costId}
					type='number'
					value={(item.price / 100).toFixed(2)}
					onChange={onPriceUpdated}
				/>
			</Box>
			<Box flex={1} mr='space.300'>
				<Select id={typeId} name={typeId} value={item.type} onChange={onTypeChanged}>
					<option value={InvoiceLineItemType.Cost}>Cost</option>
					<option value={InvoiceLineItemType.Credit}>Credit</option>
					<option value={InvoiceLineItemType.Payment}>Payment</option>
				</Select>
			</Box>
			<Box flex={1}>
				<Switch
					checked={Boolean(item.taxed)}
					disabled={item.type !== InvoiceLineItemType.Cost}
					onClick={onTaxedToggled}
				/>
			</Box>
		</Box>
	);
};

export default InvoiceLineItemEditForm;
