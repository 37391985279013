import {ProjectStatus, type UpdatedProjectInput, gql} from '../../graphql';
import {useIsMobile} from '../../util/mobile';
import CustomerPicker from '../CustomerPicker';
import DatePicker from '../DatePicker';
import InvoicePicker from '../forms/InvoicePicker';
import MoneyInput from '../forms/MoneyInput';
import ProjectStatusPicker from '../forms/ProjectStatusPicker';
import {useMutation, useQuery} from '@apollo/client';
import {Box, FormField, Input, LoaderButton, Textarea} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import React, {SyntheticEvent, useCallback, useState} from 'react';
import {formatIsoDate, parseIsoDate} from 'shared/src/datetime.js';

const UPDATE_PROJECT = gql(/* GraphQL */ `
	mutation UpdateProject($project: UpdatedProjectInput!) {
		updateProject(project: $project) {
			id
		}
	}
`);

const GET_PROJECT = gql(/* GraphQL */ `
	query GetProjectOverview($id: Int!) {
		project(id: $id) {
			id
			name
			description
			startDate
			endDate
			hoursLogged
			cost
			deposit
			status
			invoice {
				id
				description
			}
			customer {
				id
				name
			}
		}
	}
`);

interface ProjectOverviewProps {
	projectId: number;
}

const ProjectOverview = ({projectId}: ProjectOverviewProps) => {
	const isMobile = useIsMobile();

	const {data: {project} = {}} = useQuery(GET_PROJECT, {
		variables: {
			id: projectId,
		},
	});

	const [newProject, setNewProject] = useState<Partial<UpdatedProjectInput>>({});
	const updateNewProject = useCallback(
		(update: Partial<UpdatedProjectInput>) => {
			setNewProject({
				...newProject,
				...update,
			});
		},
		[newProject, setNewProject],
	);

	const [updateProject, {loading: isSaving}] = useMutation(UPDATE_PROJECT);

	const saveProject = useCallback(async () => {
		if (!project) {
			return;
		}

		await updateProject({
			variables: {
				project: {
					id: project.id,
					name: project.name,
					description: project.description,
					startDate: project.startDate,
					endDate: project.endDate,
					hoursLogged: project.hoursLogged,
					cost: project.cost,
					deposit: project.deposit,
					status: project.status,
					invoiceId: project.invoice?.id ?? null,
					customerId: project.customer.id,
					...newProject,
				},
			},
			refetchQueries: [GET_PROJECT],
		});

		// Clear "changes" because they've been saved
		setNewProject({});
	}, [newProject, updateProject, project]);

	const onUpdateStatus = useCallback(
		(status: ProjectStatus) => {
			updateNewProject({status});
		},
		[updateNewProject],
	);
	const onUpdateStartDate = useCallback(
		(startDate: DateTime) => {
			updateNewProject({startDate: formatIsoDate(startDate)});
		},
		[updateNewProject],
	);
	const onUpdateEndDate = useCallback(
		(endDate: DateTime) => {
			updateNewProject({endDate: formatIsoDate(endDate)});
		},
		[updateNewProject],
	);
	const onUpdateHoursLogged = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const {value} = event.currentTarget;
			updateNewProject({hoursLogged: value ? parseInt(value, 10) : null});
		},
		[updateNewProject],
	);
	const onUpdateCustomer = useCallback(
		(customerId: string) => {
			updateNewProject({customerId});
		},
		[updateNewProject],
	);
	const onUpdateName = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			updateNewProject({name: event.currentTarget.value});
		},
		[updateNewProject],
	);
	const onUpdateDescription = useCallback(
		(event: SyntheticEvent<HTMLTextAreaElement>) => {
			updateNewProject({description: event.currentTarget.value});
		},
		[updateNewProject],
	);
	const onUpdateCost = useCallback(
		(cost: number | null) => {
			updateNewProject({cost});
		},
		[updateNewProject],
	);
	const onUpdateDeposit = useCallback(
		(deposit: number | null) => {
			updateNewProject({deposit});
		},
		[updateNewProject],
	);
	const onUpdateInvoice = useCallback(
		(invoiceId: number) => {
			updateNewProject({invoiceId});
		},
		[updateNewProject],
	);

	if (!project) {
		return null;
	}

	const containerProps = isMobile
		? {px: 'space.300'}
		: {
				bg: 'white',
				border: '500',
				borderColor: 'container.border.base',
				borderRadius: 'outer',
				p: 'space.400',
			};

	return (
		<Box {...containerProps}>
			<FormField label='Status' maxWidth={300}>
				{(props) => (
					<ProjectStatusPicker
						{...props}
						status={newProject.status ?? project.status}
						onChange={onUpdateStatus}
					/>
				)}
			</FormField>

			<FormField label='Start Date'>
				{(props) => (
					<DatePicker
						{...props}
						value={parseIsoDate(newProject.startDate ?? project.startDate)}
						onChange={onUpdateStartDate}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='End Date'>
				{(props) => (
					<DatePicker
						{...props}
						value={
							newProject.endDate ?? project.endDate
								? parseIsoDate((newProject.endDate ?? project.endDate) as string)
								: null
						}
						onChange={onUpdateEndDate}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='Hours Logged' maxWidth={300}>
				{(props) => (
					<Input
						{...props}
						name='hoursLogged'
						value={String(newProject.hoursLogged ?? project.hoursLogged ?? '')}
						onChange={onUpdateHoursLogged}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='Customer' maxWidth={300}>
				{(props) => (
					<CustomerPicker
						{...props}
						selectedCustomerId={newProject.customerId ?? project.customer.id}
						onChange={onUpdateCustomer}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='Name' maxWidth={300}>
				{(props) => (
					<Input
						{...props}
						name='name'
						value={newProject.name ?? project.name}
						onChange={onUpdateName}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='Description' maxWidth={600}>
				{(props) => (
					<Textarea
						{...props}
						name='description'
						value={newProject.description ?? project.description}
						onChange={onUpdateDescription}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='Cost' maxWidth={300}>
				{(props) => (
					<MoneyInput
						{...props}
						name='cost'
						value={newProject.cost ?? project.cost}
						onChange={onUpdateCost}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='Deposit' maxWidth={300}>
				{(props) => (
					<MoneyInput
						{...props}
						name='deposit'
						value={newProject.deposit ?? project.deposit}
						onChange={onUpdateDeposit}
						disabled={isSaving}
					/>
				)}
			</FormField>

			<FormField label='Invoice' maxWidth={600}>
				{(props) => (
					<InvoicePicker
						{...props}
						selectedInvoiceId={newProject.invoiceId ?? project.invoice?.id ?? null}
						onChange={onUpdateInvoice}
					/>
				)}
			</FormField>

			<LoaderButton appearance='primary' minWidth={100} onClick={saveProject}>
				Save
			</LoaderButton>
		</Box>
	);
};

export default ProjectOverview;
