import {REPORTS} from '../reports';
import {isMobile} from '../util/mobile';
import {gql, useMutation} from '@apollo/client';
import {Box, Button, Icon, Text} from '@sproutsocial/racine';
import {useCallback, useState} from 'react';
import {useHref, useMatches} from 'react-router';
import {useLinkClickHandler} from 'react-router-dom';
import styled from 'styled-components';

const SYNC_DATA = gql(/* GraphQL */ `
	mutation SyncData {
		syncWithYnab
	}
`);

const MainNav = () => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
	const toggleMobileMenu = useCallback(() => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	}, [isMobileMenuOpen, setIsMobileMenuOpen]);

	if (isMobile()) {
		return (
			<>
				<Box
					as='header'
					display='flex'
					justifyContent='flex-end'
					alignItems='center'
					bg='neutral.900'
					height={48}
					px='space.300'
				>
					<Button onClick={toggleMobileMenu}>
						<Icon name='bars-density-medium-solid' size='medium' color='neutral.700' />
					</Button>
				</Box>
				<Box
					display={isMobileMenuOpen ? 'block' : 'none'}
					position='absolute'
					top={48}
					width='100%'
					bg='neutral.900'
					borderTop='500'
					borderBottom='500'
					borderColor='neutral.700'
					p='space.300'
					onClick={toggleMobileMenu}
					zIndex={10}
				>
					<NavButtons />
				</Box>
			</>
		);
	}

	return (
		<Box
			as='nav'
			width='25%'
			minWidth={240}
			maxWidth={315}
			height='100%'
			bg='neutral.900'
			display='flex'
			flexDirection='column'
			justifyContent='space-between'
		>
			<Box p='space.400'>
				<NavButtons />
			</Box>
		</Box>
	);
};

const NavButtons = () => {
	const matches = useMatches();

	const homeHref = useHref('/');
	const homeOnClick = useLinkClickHandler('/');

	const projectsHref = useHref('/projects');
	const projectsOnClick = useLinkClickHandler('/projects');

	const customersHref = useHref('/customers');
	const customersOnClick = useLinkClickHandler('/customers');

	const invoicesHref = useHref('/invoices');
	const invoicesOnClick = useLinkClickHandler('/invoices');

	const transactionsHref = useHref('/transactions');
	const transactionsOnClick = useLinkClickHandler('/transactions');

	const tripsHref = useHref('/trips');
	const tripsOnClick = useLinkClickHandler('/trips');

	const reportsHref = useHref('/reports');
	const reportsOnClick = useLinkClickHandler('/reports');

	const tagsHref = useHref('/tags');
	const tagsOnClick = useLinkClickHandler('/tags');

	const [syncData] = useMutation(SYNC_DATA);
	const onSyncData = useCallback(async () => {
		await syncData();

		window.location.reload();
	}, [syncData]);

	return (
		<>
			<NavButton
				href={homeHref}
				onClick={homeOnClick as any}
				active={matches.some(({id}) => id === 'dashboard')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='gauge-solid' mr='space.350' fixedWidth /> Dashboard
				</Text>
			</NavButton>
			<NavButton
				href={projectsHref}
				onClick={projectsOnClick as any}
				active={matches.some(({id}) => id === 'projects')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='screwdriver-hammer-solid' mr='space.350' fixedWidth /> Projects
				</Text>
			</NavButton>
			<NavButton
				href={customersHref}
				onClick={customersOnClick as any}
				active={matches.some(({id}) => id === 'customers')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='users-solid' mr='space.350' fixedWidth /> Customers
				</Text>
			</NavButton>
			<NavButton
				href={invoicesHref}
				onClick={invoicesOnClick as any}
				active={matches.some(({id}) => id === 'invoices')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='paper-solid' mr='space.350' fixedWidth /> Invoices
				</Text>
			</NavButton>
			<NavButton
				href={transactionsHref}
				onClick={transactionsOnClick as any}
				active={matches.some(({id}) => id === 'transactions')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='credit-card-solid' mr='space.350' fixedWidth /> Transactions
				</Text>
			</NavButton>
			<NavButton
				href={tripsHref}
				onClick={tripsOnClick as any}
				active={matches.some(({id}) => id === 'trips')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='gauge-solid' mr='space.350' fixedWidth /> Trips
				</Text>
			</NavButton>
			<NavButton
				href={reportsHref}
				onClick={reportsOnClick as any}
				active={matches.some(({id}) => id === 'reports')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='chart-area-solid' mr='space.350' fixedWidth /> Reports
				</Text>
			</NavButton>
			<Box ml={40}>
				<ReportButton reportId='income' reportName='Income' />
				<ReportButton reportId='sales-tax' reportName='Sales Tax' />
				{REPORTS.map((report) => (
					<ReportButton key={report.id} reportId={report.id} reportName={report.name} />
				))}
			</Box>
			<NavButton
				href={tagsHref}
				onClick={tagsOnClick as any}
				active={matches.some(({id}) => id === 'tags')}
			>
				<Text fontSize='200' color='white'>
					<Icon name='tag-solid' mr='space.350' fixedWidth /> Tags
				</Text>
			</NavButton>
			<NavButton onClick={onSyncData}>
				<Text fontSize='200' color='white'>
					<Icon name='arrows-rotate-solid' mr='space.350' fixedWidth /> Sync Data
				</Text>
			</NavButton>
		</>
	);
};

const NavButton = styled(Button).withConfig({
	shouldForwardProp: (prop) => prop !== 'active',
})`
	${({active, theme}) => `
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: white;
	border-radius: 999px;
	padding: ${theme.space['350']} ${theme.space['400']};
	
	background-color: ${active ? theme.colors.neutral['700'] : 'none'};
	
	&:focus {
		box-shadow: none;
	}
	
	&:visited {
		color: white;
	}
	
	&:hover {
		color: white;
		background-color: ${theme.colors.neutral['1000']};
	}
`}
`;

const SecondaryNavButton = styled(Button).withConfig({
	shouldForwardProp: (prop) => prop !== 'active',
})`
	${({active, theme}) => `
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: white;
	font-weight: ${theme.fontWeights.normal};
	margin-bottom: ${theme.space[300]};
	width: 100%;

	background-color: ${active ? theme.colors.neutral[700] : 'none'};
	
	&:focus {
		box-shadow: none;
	}
	
	&:visited {
		color: white;
	}
	
	&:hover {
		color: white;
		background-color: ${theme.colors.neutral[1000]};
	}
`}
`;

const ReportButton = ({reportId, reportName}: {reportId: string; reportName: string}) => {
	const matches = useMatches();

	const href = useHref(`/reports/${reportId}`);
	const onClick = useLinkClickHandler(`/reports/${reportId}`);
	const active =
		matches.some(({id}) => id === 'reports') &&
		matches.some(({id, params}) => id === 'specific-report' && params.reportId === reportId);

	return (
		<SecondaryNavButton href={href} onClick={onClick as any} active={active}>
			{reportName}
		</SecondaryNavButton>
	);
};

export default MainNav;
