import {gql} from '../../graphql';
import TagName from './TagName';
import {useQuery} from '@apollo/client';
import {Box, Checkbox} from '@sproutsocial/racine';
import {type SyntheticEvent, useCallback} from 'react';

interface TagPickerProps {
	selectedTagIds: Set<number>;
	indeterminateTagIds?: Set<number>;
	onTagDeselected: (tagId: number) => void;
	onTagSelected: (tagId: number) => void;
}

const GET_TAGS = gql(/* GraphQL */ `
	query GetTags {
		tags {
			id
		}
	}
`);

const TagPicker = (props: TagPickerProps) => {
	const {data: {tags} = {}} = useQuery(GET_TAGS);

	const {selectedTagIds, indeterminateTagIds, onTagDeselected, onTagSelected} = props;

	const onCheckboxChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const tagId = parseInt(event.currentTarget.value, 10);

			if (selectedTagIds.has(tagId)) {
				if (indeterminateTagIds?.has(tagId)) {
					onTagSelected(tagId);
				} else {
					onTagDeselected(tagId);
				}
			} else {
				onTagSelected(tagId);
			}
		},
		[selectedTagIds, indeterminateTagIds, onTagSelected, onTagDeselected],
	);

	return (
		<Box display='flex' flexDirection='column'>
			{tags?.map((tag) => (
				<Box key={tag.id} display='flex' alignItems='center' mb='space.200'>
					<Checkbox
						id={`checkbox-${tag.id}`}
						checked={selectedTagIds.has(tag.id)}
						indeterminate={indeterminateTagIds?.has(tag.id)}
						value={String(tag.id)}
						onChange={onCheckboxChange}
						mr='space.300'
					/>

					<TagName id={tag.id} includeParents showIcon />
				</Box>
			))}
		</Box>
	);
};

export default TagPicker;
