import {initializeReports} from './reports';
import {DateTime} from 'luxon';

export const initializeApp = async () => {
	injectGlobalDebugValues();
	await initializeReports();
};

const injectGlobalDebugValues = () => {
	// For debugging
	(window as any).DateTime = DateTime;
};
