import {Note, gql} from '../../graphql';
import {List} from '../List';
import MobileFriendlyItemDisplay from '../MobileFriendlyItemDisplay';
import QuillEditor, {type QuillContent} from '../QuillEditor';
import Table from '../Table';
import {useMutation, useQuery} from '@apollo/client';
import {Box, Button, Icon, Input, LoaderButton, Modal} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {orderBy} from 'shared/src/list';

interface ProjectNotesProps {
	projectId: number;
}

const GET_PROJECT = gql(/* GraphQL */ `
	query GetProjectNotes($id: Int!) {
		project(id: $id) {
			id
			notes {
				id
				name
				content
			}
		}
	}
`);

const CREATE_NOTE = gql(/* GraphQL */ `
	mutation CreateNote($note: NewNoteInput!, $projectId: Int!) {
		createNote(note: $note, projectId: $projectId) {
			id
		}
	}
`);

const UPDATE_NOTE = gql(/* GraphQL */ `
	mutation UpdateNote($note: UpdatedNoteInput!) {
		updateNote(note: $note) {
			id
		}
	}
`);

const DELETE_NOTE = gql(/* GraphQL */ `
	mutation DeleteProjectNote($id: Int!) {
		deleteNote(id: $id)
	}
`);

const ProjectNotes = ({projectId}: ProjectNotesProps) => {
	const {data: {project} = {}} = useQuery(GET_PROJECT, {
		variables: {
			id: projectId,
		},
	});

	const notes = useMemo(() => project && orderBy(project.notes, 'name'), [project]);

	const [editingNote, setEditingNote] = useState<(Omit<Note, 'id'> & {id: number | null}) | null>();
	const onUpdateEditingNoteName = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
		const name = event.currentTarget.value;

		setEditingNote((note) => ({
			...note!,
			name,
		}));
	}, []);
	const onUpdateEditingNoteContent = useCallback((newContent: QuillContent) => {
		setEditingNote((note) => ({
			...note!,
			content: newContent,
		}));
	}, []);

	const [createNote, {loading: isCreatingNote}] = useMutation(CREATE_NOTE);
	const [updateNote, {loading: isUpdatingNote}] = useMutation(UPDATE_NOTE);
	const [deleteNote] = useMutation(DELETE_NOTE);

	const editCallbacks = useMemo(() => {
		return notes?.reduce((callbacks, note) => {
			callbacks.set(note.id, async (event: SyntheticEvent<any>) => {
				event.stopPropagation();

				setEditingNote(note);
			});

			return callbacks;
		}, new Map());
	}, [notes]);

	const deleteCallbacks = useMemo(() => {
		return notes?.reduce((callbacks, note) => {
			callbacks.set(note.id, async (event: SyntheticEvent<any>) => {
				event.stopPropagation();

				await deleteNote({
					variables: {
						id: note.id,
					},
					refetchQueries: [GET_PROJECT],
				});
			});

			return callbacks;
		}, new Map());
	}, [notes, deleteNote]);

	const onCreateNote = useCallback(() => {
		setEditingNote({
			id: null,
			name: '',
			content: undefined,
		});
	}, []);

	const onCancelEditing = useCallback(() => {
		setEditingNote(null);
	}, []);

	const onSaveNote = useCallback(async () => {
		if (editingNote?.id) {
			await updateNote({
				variables: {
					note: {
						...editingNote,
						id: editingNote.id!,
					},
				},
				refetchQueries: [GET_PROJECT],
			});
		} else {
			await createNote({
				variables: {
					note: {
						name: editingNote!.name,
						content: editingNote!.content,
					},
					projectId,
				},
				refetchQueries: [GET_PROJECT],
			});
		}

		setEditingNote(null);
	}, [projectId, editingNote, updateNote, createNote]);

	if (!notes || !deleteCallbacks || !editCallbacks) {
		return null;
	}

	return (
		<Box>
			<Box display='flex' justifyContent='flex-end'>
				<Button appearance='primary' onClick={onCreateNote} mb='space.400'>
					<Icon name='plus' mr='space.300' />
					Create Note
				</Button>
			</Box>
			<MobileFriendlyItemDisplay
				list={
					<List id={`project-${projectId}-notes`}>
						{notes.map((note) => (
							<List.Item key={note.id} title={note.name} />
						))}
					</List>
				}
				table={
					<Table
						id={`project-${projectId}-notes`}
						head={[
							{
								id: 'name',
								content: 'Name',
							},
							{
								id: 'actions',
								content: null,
							},
						]}
						items={notes}
						generateRow={(note) => ({
							id: note.id,
							cells: [
								note.name,
								<Box display='flex' justifyContent='flex-end' columnGap='space.300'>
									<Icon
										name='pencil-to-square-solid'
										cursor='pointer'
										onClick={editCallbacks.get(note.id)}
										color='button.primary.background.base'
									/>
									<Icon
										name='trash-solid'
										cursor='pointer'
										onClick={deleteCallbacks.get(note.id)}
										color='button.primary.background.base'
									/>
								</Box>,
							],
							drawerContents: <QuillEditor content={note.content} readOnly />,
						})}
					/>
				}
			/>
			{editingNote && (
				<Modal isOpen label='Edit Note' closeButtonLabel='Close' onClose={onCancelEditing}>
					<Modal.Header title='Edit Note' />
					<Modal.Content>
						<Input
							id='edited-note'
							name='edited-note'
							placeholder='Project Name'
							value={editingNote.name}
							onChange={onUpdateEditingNoteName}
							mb='space.300'
						/>
						<QuillEditor content={editingNote.content} onChange={onUpdateEditingNoteContent} />
					</Modal.Content>
					<Modal.Footer display='flex' justifyContent='flex-end'>
						<Button onClick={onCancelEditing} mr='space.300'>
							Cancel
						</Button>
						<LoaderButton
							appearance='primary'
							onClick={onSaveNote}
							isLoading={isCreatingNote || isUpdatingNote}
							minWidth={80}
							disabled={!editingNote.name.trim() || !editingNote.content}
						>
							{editingNote.id ? 'Save' : 'Create'}
						</LoaderButton>
					</Modal.Footer>
				</Modal>
			)}
		</Box>
	);
};

export default ProjectNotes;
