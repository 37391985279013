import {type TableTransaction} from './graphql';
import {once} from './util/function';
import {DateTime} from 'luxon';

export interface ReportConfig {
	id: string;
	name: string;

	startDate: DateTime;
	endDate: DateTime;
	categoryIds?: number[];
	tagIds?: number[];
	excludedTagIds?: number[];

	// Applied AFTER all of the above filters
	customFilter?: (transaction: TableTransaction) => boolean;
}

export let REPORTS: ReportConfig[] = [];

export const initializeReports: () => Promise<ReportConfig[]> = once(async () => {
	REPORTS = [
		{
			id: '2024-total',
			name: '2024 Total',
			startDate: DateTime.fromISO('2024-01-01'),
			endDate: DateTime.fromISO('2024-12-31'),
		},
		{
			id: '2024-income',
			name: '2024 Total Income',
			startDate: DateTime.fromISO('2024-01-01'),
			endDate: DateTime.fromISO('2024-12-31'),
			customFilter: (transaction) => transaction.amount > 0,
		},
		{
			id: '2023-total',
			name: '2023 Total',
			startDate: DateTime.fromISO('2023-01-01'),
			endDate: DateTime.fromISO('2023-12-31'),
		},
		{
			id: '2023-income',
			name: '2023 Total Income',
			startDate: DateTime.fromISO('2023-01-01'),
			endDate: DateTime.fromISO('2023-12-31'),
			tagIds: [],
			customFilter: (transaction) => transaction.amount > 0,
		},
	];

	return REPORTS;
});
