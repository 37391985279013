import {Invoice, InvoiceLineItem} from '../../models';
import CustomerPicker from '../CustomerPicker';
import DatePicker from '../DatePicker';
import InvoiceLineItemsEditForm from './InvoiceLineItemsEditForm';
import {Box, FormField, Input, Textarea} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {SyntheticEvent, useCallback, useRef} from 'react';

interface InvoiceEditFormProps {
	invoice: Invoice;
	onInvoiceUpdated: (updatedInvoice: Invoice) => void;
}

const InvoiceEditForm = ({invoice, onInvoiceUpdated}: InvoiceEditFormProps) => {
	// Use refs to avoid creating new callbacks on every keystroke
	const invoiceRef = useRef<Invoice>(invoice);
	invoiceRef.current = invoice;

	const onDateChange = useCallback(
		(datetime: DateTime) => {
			const updatedInvoice = invoiceRef.current.clone();
			updatedInvoice.date = datetime;

			onInvoiceUpdated(updatedInvoice);
		},
		[onInvoiceUpdated],
	);

	const onDescriptionChange = useCallback(
		(event: SyntheticEvent<HTMLTextAreaElement>) => {
			const updatedInvoice = invoiceRef.current.clone();
			updatedInvoice.description = event.currentTarget.value;

			onInvoiceUpdated(updatedInvoice);
		},
		[onInvoiceUpdated],
	);

	const onCustomerChanged = useCallback(
		(customerId: string) => {
			const updatedInvoice = invoiceRef.current.clone();
			updatedInvoice.customerId = customerId;

			onInvoiceUpdated(updatedInvoice);
		},
		[onInvoiceUpdated],
	);

	const onLineItemUpdated = useCallback(
		(updatedLineItem: InvoiceLineItem) => {
			const updatedInvoice = invoiceRef.current!.clone();
			updatedInvoice.items = updatedInvoice.items.map((item) => {
				return item.id === updatedLineItem.id ? updatedLineItem : item;
			});

			onInvoiceUpdated(updatedInvoice);
		},
		[onInvoiceUpdated, invoiceRef],
	);

	return (
		<Box>
			<Box borderBottom='500' borderColor='container.border.base' pb='space.400' mb='space.400'>
				<Box width={300}>
					<FormField label='Customer'>
						{(props) => (
							<CustomerPicker
								{...props}
								selectedCustomerId={invoice.customerId}
								onChange={onCustomerChanged}
							/>
						)}
					</FormField>
				</Box>
			</Box>
			<Box borderBottom='500' borderColor='container.border.base' mb='space.400'>
				{invoice.id && (
					<Box width={130}>
						<FormField label='Invoice ID'>
							{(props) => (
								<Input
									{...props}
									name='invoiceID'
									type='number'
									value={invoice.id?.toString() ?? ''}
									disabled
								/>
							)}
						</FormField>
					</Box>
				)}

				<FormField label='Issue Date'>
					{(props) => <DatePicker {...props} value={invoice.date} onChange={onDateChange} />}
				</FormField>

				<FormField label='Description'>
					{(props) => (
						<Textarea
							{...props}
							name='description'
							value={invoice.description}
							onChange={onDescriptionChange}
						/>
					)}
				</FormField>
			</Box>
			<Box>
				<InvoiceLineItemsEditForm items={invoice.items} onChange={onLineItemUpdated} />
			</Box>
		</Box>
	);
};

export default InvoiceEditForm;
