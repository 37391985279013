import {DateTime} from 'luxon';
import {createContext, useContext} from 'react';

export interface ReportsContextContent {
	reportingPeriod: {
		startDate: DateTime;
		endDate: DateTime;
		onChange: (startDate: DateTime, endDate: DateTime) => void;
	};
}

const defaultValue: ReportsContextContent = {
	reportingPeriod: {
		startDate: DateTime.now().startOf('month'),
		endDate: DateTime.now().endOf('day'),
		onChange: () => {},
	},
};

export const ReportsContext = createContext<ReportsContextContent>(defaultValue);

export const useReportsContext = (): ReportsContextContent => {
	return useContext(ReportsContext);
};
