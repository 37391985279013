import {ReportsContext, type ReportsContextContent} from '../../context/reports-context';
import {useLocalStorageState} from '../../hooks';
import {DateTime} from 'luxon';
import {useCallback, useMemo} from 'react';
import {Outlet} from 'react-router';

const ReportsShell = () => {
	const [startDate, setStartDate] = useLocalStorageState<DateTime>({
		localStorageKey: 'reports.reporting-period.start-date',
		defaultValue: DateTime.now().startOf('month'),
		serialize: (datetime) => datetime.toISO(),
		deserialize: (json) =>
			DateTime.fromISO(json).isValid ? DateTime.fromISO(json) : DateTime.now().startOf('month'),
	});

	const [endDate, setEndDate] = useLocalStorageState<DateTime>({
		localStorageKey: 'reports.reporting-period.end-date',
		defaultValue: DateTime.now().endOf('day'),
		serialize: (datetime) => datetime.toISO(),
		deserialize: (json) =>
			DateTime.fromISO(json).isValid ? DateTime.fromISO(json) : DateTime.now().endOf('day'),
	});

	const onReportingPeriodChange = useCallback(
		(startDate: DateTime, endDate: DateTime) => {
			setStartDate(startDate);
			setEndDate(endDate);
		},
		[setStartDate, setEndDate],
	);

	const reportingPeriod = useMemo<ReportsContextContent['reportingPeriod']>(
		() => ({
			startDate,
			endDate,
			onChange: onReportingPeriodChange,
		}),
		[startDate, endDate, onReportingPeriodChange],
	);

	return (
		<ReportsContext.Provider value={{reportingPeriod}}>
			<Outlet />
		</ReportsContext.Provider>
	);
};

export default ReportsShell;
