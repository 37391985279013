import {formatCurrency} from '../formatters';
import {gql} from '../graphql';
import {useExpenseTransactions, useIncomeTransactions} from '../graphql/transaction-hooks';
import {getTransactionsTotal} from '../helpers/transactions';
import {useQuery} from '@apollo/client';
import {Box, Text} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {useMemo} from 'react';

const GET_ACCOUNTS = gql(/* GraphQL */ `
	query GetDashboardAccounts {
		accounts {
			id
			name
			balance
			closed
		}
	}
`);

export default function Dashboard() {
	const {data: {accounts} = {}} = useQuery(GET_ACCOUNTS);

	const {transactions: incomeTransactions2023} = useIncomeTransactions({
		startDate: DateTime.fromISO('2023-01-01'),
		endDate: DateTime.fromISO('2023-12-31'),
	});

	const {transactions: expenseTransactions2023} = useExpenseTransactions({
		startDate: DateTime.fromISO('2023-01-01'),
		endDate: DateTime.fromISO('2023-12-31'),
	});

	const {transactions: incomeTransactions2024} = useIncomeTransactions({
		startDate: DateTime.fromISO('2024-01-01'),
		endDate: DateTime.fromISO('2024-12-31'),
	});

	const {transactions: expenseTransactions2024} = useExpenseTransactions({
		startDate: DateTime.fromISO('2024-01-01'),
		endDate: DateTime.fromISO('2024-12-31'),
	});

	const income2023 = useMemo(
		() => getTransactionsTotal(incomeTransactions2023 ?? []),
		[incomeTransactions2023],
	);
	const expenses2023 = useMemo(
		() => getTransactionsTotal(expenseTransactions2023 ?? []),
		[expenseTransactions2023],
	);
	const income2024 = useMemo(
		() => getTransactionsTotal(incomeTransactions2024 ?? []),
		[incomeTransactions2024],
	);
	const expenses2024 = useMemo(
		() => getTransactionsTotal(expenseTransactions2024 ?? []),
		[expenseTransactions2024],
	);

	const operatingCapital =
		accounts?.reduce((operatingCapital, account) => {
			return operatingCapital + account.balance;
		}, 0) ?? 0;

	return (
		<Box p='space.400'>
			<Box
				display='flex'
				flexDirection='column'
				border='500'
				borderRadius='outer'
				borderColor='container.border.base'
				bg='white'
				mb='space.400'
			>
				<Box p='space.300'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='300' fontWeight='semibold'>
							Operating Capital
						</Text>
						<Text fontSize='300' fontWeight='semibold'>
							{operatingCapital === null ? '–' : formatCurrency(operatingCapital)}
						</Text>
					</Box>
				</Box>
				{accounts
					?.filter((account) => !account.closed)
					.map((account) => (
						<Box key={account.id} p='space.300' borderTop='500' borderColor='container.border.base'>
							<Box display='flex' justifyContent='space-between'>
								<Text fontSize='200' mr='space.300'>
									{account.name}
								</Text>
								<Text fontSize='200'>{formatCurrency(account.balance)}</Text>
							</Box>
						</Box>
					))}
			</Box>

			<Box
				display='flex'
				flexDirection='column'
				border='500'
				borderRadius='outer'
				borderColor='container.border.base'
				bg='white'
				mb='space.400'
			>
				<Box p='space.300'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='300' fontWeight='semibold'>
							2024 Profit
						</Text>
						<Text fontSize='300' fontWeight='semibold'>
							{formatCurrency(income2023 + expenses2023)}
						</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Income
						</Text>
						<Text fontSize='200'>{formatCurrency(income2023)}</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Expenses
						</Text>
						<Text fontSize='200'>{formatCurrency(Math.abs(expenses2023))}</Text>
					</Box>
				</Box>
			</Box>

			<Box
				display='flex'
				flexDirection='column'
				border='500'
				borderRadius='outer'
				borderColor='container.border.base'
				bg='white'
				mb='space.400'
			>
				<Box p='space.300'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='300' fontWeight='semibold'>
							2023 Profit
						</Text>
						<Text fontSize='300' fontWeight='semibold'>
							{formatCurrency(income2024 + expenses2024)}
						</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Income
						</Text>
						<Text fontSize='200'>{formatCurrency(income2024)}</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Expenses
						</Text>
						<Text fontSize='200'>{formatCurrency(Math.abs(expenses2024))}</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
