type ArrayOrSet<V> = Set<V> | V[];

export const intersection = <V>(a: ArrayOrSet<V>, b: ArrayOrSet<V>): Set<V> => {
	const aSet = a instanceof Set ? a : new Set(a);
	const bSet = b instanceof Set ? b : new Set(b);

	const intersection = new Set<V>();

	aSet.forEach((value) => {
		if (bSet.has(value)) {
			intersection.add(value);
		}
	});

	return intersection;
};

export const union = <V>(a: ArrayOrSet<V>, b: ArrayOrSet<V>): Set<V> => {
	const aSet = a instanceof Set ? a : new Set(a);
	const bSet = b instanceof Set ? b : new Set(b);

	bSet.forEach((value) => {
		aSet.add(value);
	});

	return aSet;
};

export const difference = <V>(a: ArrayOrSet<V>, b: ArrayOrSet<V>): Set<V> => {
	const aSet = a instanceof Set ? a : new Set(a);

	b.forEach((value) => {
		aSet.delete(value);
	});

	return aSet;
};

export const changes = <V>(
	previous: ArrayOrSet<V>,
	next: ArrayOrSet<V>,
): {added: Set<V>; removed: Set<V>} => {
	return {
		added: difference(next, previous),
		removed: difference(previous, next),
	};
};

export const equal = (a: ArrayOrSet<any>, b: ArrayOrSet<any>): boolean => {
	// We don't care about duplicates in an array. These operate on "sets"
	const aSet = a instanceof Set ? a : new Set(a);
	const bSet = b instanceof Set ? b : new Set(b);

	if (aSet.size !== bSet.size) {
		return false;
	}

	for (const [value] of aSet.entries()) {
		if (!bSet.has(value)) {
			return false;
		}
	}

	for (const [value] of bSet.entries()) {
		if (!aSet.has(value)) {
			return false;
		}
	}

	return true;
};
