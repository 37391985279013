import {useSetState} from '../hooks';
import {
	Box,
	Checkbox,
	Table as SproutTable,
	TypeTableCellProps as SproutTableCellProps,
	TableRowAccordion as SproutTableRowAccordion,
	Text,
} from '@sproutsocial/racine';
import {type ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {equal, intersection} from 'shared/src/set';
import styled from 'styled-components';

interface TableProps<V> {
	id: string;
	title?: ReactNode;
	head: TableHeaderCell[];
	fixedRow?: TableRow;
	items: V[];
	generateRow: (item: V) => TableRow;
	sort?: (a: V, b: V, column: string) => number;
	allowSelection?: boolean;
	onRowsSelected?: (selectedRowIds: Set<string>) => void;
}

interface TableHeaderCell {
	id: string;
	shouldTruncate?: boolean;
	isSortable?: boolean;
	content: ReactNode;
}

interface TableRow {
	id: string | number;
	cells: ReactNode[];
	drawerContents?: ReactNode;
}

export default function Table<V>({
	id,
	title,
	head,
	fixedRow,
	items,
	generateRow,
	sort,
	allowSelection,
	onRowsSelected,
}: TableProps<V>) {
	const [sortId, setSortId] = useState<string>();
	const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

	const onSort = useCallback(
		(id: string) => {
			if (id === sortId) {
				setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
			} else {
				setSortId(id);
				setSortDirection('ASC');
			}
		},
		[sortId, sortDirection],
	);

	const rows = useMemo(() => {
		let sortedItems = items;
		if (sort && sortId) {
			sortedItems = items.sort((a, b) => {
				return sort(a, b, sortId);
			});

			if (sortDirection === 'DESC') {
				sortedItems = sortedItems.reverse();
			}
		}

		return sortedItems.map((item) => {
			const row = generateRow(item);

			return {
				id: String(row.id),
				cells: row.cells.map((content, i) => ({
					id: head[i].id,
					content,
				})),
				drawerContents: row.drawerContents,
			};
		});
	}, [head, items, generateRow, sort, sortId, sortDirection]);

	const collapsible = useMemo(() => rows.some((row) => row.drawerContents), [rows]);

	const allRowIds = useMemo<Set<string>>(() => new Set(rows.map((row) => row.id)), [rows]);

	const expandedRows = useSetState<string>();
	const toggleRowCollapse = useCallback(
		(rowId: string) => {
			if (expandedRows.has(rowId)) {
				expandedRows.delete(rowId);
			} else {
				expandedRows.add(rowId);
			}
		},
		[expandedRows],
	);

	const selectedRows = useSetState<string>();
	const allRowsSelected = useMemo(
		() => Array.from(allRowIds).every((id) => selectedRows.has(id)),
		[allRowIds, selectedRows],
	);
	const someRowsSelected = useMemo(
		() => Array.from(allRowIds).some((id) => selectedRows.has(id)),
		[allRowIds, selectedRows],
	);
	const selectAllRows = useCallback(() => {
		if (allRowsSelected) {
			selectedRows.clear();
		} else {
			allRowIds.forEach((id) => {
				selectedRows.add(id);
			});
		}

		onRowsSelected?.(new Set(selectedRows));
	}, [onRowsSelected, allRowIds, selectedRows, allRowsSelected]);

	const toggleRowSelection = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const rowId = event.currentTarget.value;

			if (selectedRows.has(rowId)) {
				selectedRows.delete(rowId);
			} else {
				selectedRows.add(rowId);
			}

			onRowsSelected?.(new Set(selectedRows));
		},
		[selectedRows, onRowsSelected],
	);

	// Make sure that our selected IDs state doesn't include non-existent IDs
	useEffect(() => {
		const newExpandedRows = intersection(expandedRows, allRowIds);
		if (!equal(newExpandedRows, expandedRows)) {
			expandedRows.clear();
			newExpandedRows.forEach((id) => expandedRows.add(id));
		}

		const newSelectedRows = intersection(selectedRows, allRowIds);
		if (!equal(newSelectedRows, selectedRows)) {
			selectedRows.clear();
			newSelectedRows.forEach((id) => selectedRows.add(id));

			onRowsSelected?.(new Set(selectedRows));
		}
	}, [allRowIds, expandedRows, selectedRows, onRowsSelected]);

	const updatedHead = useMemo(() => {
		return [
			...(allowSelection
				? [
						{
							id: 'selected',
							content: (
								<Box display='flex' alignItems='center'>
									<Checkbox
										id='select-all-table-items'
										checked={someRowsSelected}
										indeterminate={someRowsSelected && !allRowsSelected}
										onChange={selectAllRows}
									/>
								</Box>
							),
						},
					]
				: []),
			...head,
			...(collapsible ? [{id: 'collapsible'}] : []),
		];
	}, [head, allowSelection, collapsible, allRowsSelected, someRowsSelected, selectAllRows]);

	const updatedRows = useMemo(() => {
		return [
			...(fixedRow
				? [
						{
							id: 'fixed',
							cells: [
								...(allowSelection ? [{id: 'selected'}] : []),
								...fixedRow.cells.map((content, i) => ({
									id: head[i].id,
									content,
								})),
							],
						},
					]
				: []),
			...rows.map((row) => {
				return {
					...row,
					cells: [
						...(allowSelection
							? [
									{
										id: 'selected',
										content: (
											<Box display='flex' alignItems='center'>
												<Checkbox
													id={`select-${row.id}`}
													value={String(row.id)}
													checked={selectedRows.has(row.id)}
													onChange={toggleRowSelection}
													onClick={stopPropagationClick}
												/>
											</Box>
										),
									},
								]
							: []),
						...row.cells,
					],
				};
			}),
		];
	}, [rows, selectedRows, toggleRowSelection, allowSelection, head, fixedRow]);

	return (
		<Box display='flex' flexDirection='column'>
			{title && (
				<Text
					as='div'
					fontSize='300'
					fontWeight='semibold'
					color='text.headline'
					mb='space.300'
					pl='space.200'
				>
					{title}
				</Text>
			)}
			<Box
				bg='white'
				border='500'
				borderColor='container.border.base'
				borderRadius='outer'
				p='space.300'
			>
				<StickyTable
					id={id}
					head={updatedHead}
					rows={updatedRows}
					sortId={sortId}
					sortDirection={sortDirection}
					onSort={onSort}
					rowRender={(row: {
						id: string;
						cells: SproutTableCellProps[];
						drawerContents?: ReactNode;
					}) => (
						<SwitchableTableRowAccordion
							key={row.id}
							id={row.id}
							cells={row.cells}
							disableToggle={!row.drawerContents}
							isExpanded={Boolean(row.drawerContents) && expandedRows.has(row.id)}
							onToggle={toggleRowCollapse}
							detail={row.drawerContents}
						/>
					)}
				/>
			</Box>
		</Box>
	);
}

const StickyTable = styled(SproutTable)`
	thead {
		position: sticky;
		top: 0;
		background: white;
		z-index: 5;
		tr {
			box-shadow: inset 0 -2px 0 ${({theme}) => theme.colors.neutral[200]};

			th {
				padding-top: 12px;
			}
		}
	}
`;

const SwitchableTableRowAccordion = styled(SproutTableRowAccordion)<{disableToggle?: boolean}>`
	td:has(> [data-tablerowaccordion-trigger]) {
		display: ${({disableToggle}) => (disableToggle ? 'none' : 'table-cell')};
		text-align: right;
		width: 0;
	}
`;

const stopPropagationClick = (event: SyntheticEvent<unknown>) => event.stopPropagation();
