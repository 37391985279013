import {focusRing} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {type SyntheticEvent, useCallback} from 'react';
import styled from 'styled-components';

interface DatePickerProps {
	value: DateTime | null;
	minDate?: DateTime;
	maxDate?: DateTime;
	disabled?: boolean;
	onChange: (datetime: DateTime) => void;
}

export default function DatePicker({value, minDate, maxDate, disabled, onChange}: DatePickerProps) {
	const handleChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;

			onChange(DateTime.fromISO(value));
		},
		[onChange],
	);

	return (
		<StyledDateTimeInput
			type='date'
			value={value ? value.setZone('local').toFormat('yyyy-LL-dd') : ''}
			min={minDate ? minDate.toFormat('yyyy-LL-dd') : undefined}
			max={maxDate ? maxDate.toFormat('yyyy-LL-dd') : undefined}
			disabled={disabled}
			onChange={handleChange}
		/>
	);
}

const StyledDateTimeInput = styled.input`
	${({theme}) => `
		border: 1px solid ${theme.colors.neutral[400]};
		border-radius: ${theme.radii.inner};
		padding: ${theme.space[200]} ${theme.space[300]};
		font-size: ${theme.typography[200].fontSize};
		line-height: ${theme.typography[200].lineHeight};
		font-family: ${theme.fontFamily};
		font-weight: ${theme.fontWeights.normal};
		color: ${theme.colors.text.body};
	`}

	:focus, :focus-visible {
		${focusRing}
	}
`;
