import {Category, CategoryGroup} from './__generated__/graphql';
import {DateTime} from 'luxon';

const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export function formatCurrency(priceInPennies: number): string {
	return currencyFormatter.format(priceInPennies / 100);
}

const integerFormatter = new Intl.NumberFormat('en-US', {
	style: 'decimal',
});

export function formatInteger(value: number): string {
	return integerFormatter.format(value);
}

/** @deprecated */
export function formatDate(dateTime: DateTime): string {
	return dateTime.toFormat('yyyy-MM-dd');
}

export function formatFileSize(bytes: number) {
	if (bytes === 0) {
		return '0 B';
	}

	const sizes = ['B', 'KB', 'MB', 'GB'];
	const magnitude = Math.floor(Math.log(bytes) / Math.log(1024));

	const decimals = magnitude <= 1 ? 0 : 2;

	return parseFloat((bytes / Math.pow(1024, magnitude)).toFixed(decimals)) + ' ' + sizes[magnitude];
}

type MinimumCategory = Pick<Category, 'name'> & {
	categoryGroup?: Pick<CategoryGroup, 'name'> | null;
};

export function formatTransactionCategory(category: MinimumCategory): string {
	if (category.categoryGroup) {
		return `${category.categoryGroup.name}: ${category.name}`;
	} else {
		return category.name;
	}
}
