import {gql} from '../../graphql';
import PDFPage from '../PDFPage';
import InvoiceFooterPreview from './InvoiceFooterPreview';
import InvoiceHeaderPreview from './InvoiceHeaderPreview';
import InvoiceLineItemPreview from './InvoiceLineItemPreview';
import InvoiceLineItemsHeaderPreview from './InvoiceLineItemsHeaderPreview';
import {useQuery} from '@apollo/client';
import {Box} from '@sproutsocial/racine';

interface InvoicePreviewProps {
	invoiceId: number;
}

const GET_INVOICE = gql(/* GraphQL */ `
	query GetInvoicePreviewShell($id: Int!) {
		invoice(id: $id) {
			id
			items {
				id
				type
				name
				description
				price
				quantity
				taxed
			}
		}
	}
`);

const InvoicePreview = ({invoiceId}: InvoicePreviewProps) => {
	const {data: {invoice} = {}} = useQuery(GET_INVOICE, {
		variables: {id: invoiceId},
	});

	if (!invoice) {
		return null;
	}

	return (
		<Box bg='white'>
			<PDFPage>
				<InvoiceHeaderPreview invoiceId={invoiceId} />

				<Box my='space.500'>
					<InvoiceLineItemsHeaderPreview />
					{invoice.items.map((item) => (
						<InvoiceLineItemPreview key={item.id} lineItem={item} />
					))}
				</Box>

				<InvoiceFooterPreview invoiceId={invoiceId} />
			</PDFPage>
		</Box>
	);
};

export default InvoicePreview;
