import {ProjectStatus, type Transaction} from '../graphql';

type MinimumProject = {
	transactions: Array<Pick<Transaction, 'amount'>>;
};

export const calculateProjectProfit = (project: MinimumProject): number => {
	return project.transactions.reduce((total, transaction) => total + transaction.amount, 0);
};

export const formatProjectStatus = (status: ProjectStatus): string => {
	switch (status) {
		case ProjectStatus.Upcoming:
			return 'Upcoming';
		case ProjectStatus.Planning:
			return 'Planning';
		case ProjectStatus.InProgress:
			return 'In Progress';
		case ProjectStatus.Done:
			return 'Done';
		case ProjectStatus.Cancelled:
			return 'Cancelled';
		case ProjectStatus.FollowUp:
			return 'Follow Up';
	}
};
