import {useReportsContext} from '../../context/reports-context';
import {useIncomeTransactions} from '../../graphql/transaction-hooks';
import TransactionsTable from '../transactions/TransactionsTable';
import ReportsLayout from './ReportsLayout';

const IncomeReport = () => {
	const {reportingPeriod} = useReportsContext();

	const {transactions, refetch: refetchTransactions} = useIncomeTransactions({
		startDate: reportingPeriod.startDate,
		endDate: reportingPeriod.endDate,
	});

	return (
		<ReportsLayout title='Income Report'>
			<TransactionsTable
				id='income-report'
				transactions={transactions ?? []}
				onChange={refetchTransactions}
			/>
		</ReportsLayout>
	);
};

export default IncomeReport;
