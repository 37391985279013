import {type Tag, gql} from '../../graphql';
import {useTags} from '../../graphql/tag-hooks';
import Layout from '../Layout';
import TagName from './TagName';
import TagToken from './TagToken';
import {useMutation} from '@apollo/client';
import {Box, Icon, Input, Listbox, ListboxButton, LoaderButton, Text} from '@sproutsocial/racine';
import {type SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {TAG_COLORS} from 'shared/src/tags.js';

const CREATE_TAG = gql(/* GraphQL */ `
	mutation CreateTag($newTag: NewTagInput) {
		createTag(newTag: $newTag) {
			id
		}
	}
`);

const TagManagement = () => {
	const {tags, refetch: refetchTags} = useTags();

	const [tagName, setTagName] = useState('');
	const onTagNameChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			setTagName(event.currentTarget.value);
		},
		[setTagName],
	);

	const [selectedColor, setSelectedColor] = useState('red.500');

	const [parentTagId, setParentTagId] = useState<string>('0');
	const parentTag = useMemo(() => {
		if (!tags || !parentTagId) {
			return null;
		}

		return tags.find((tag) => tag.id === parseInt(parentTagId, 10))!;
	}, [tags, parentTagId]);

	const [createTag, {loading: isCreating}] = useMutation(CREATE_TAG);

	const onCreateTag = useCallback(async () => {
		await createTag({
			variables: {
				newTag: {
					name: tagName,
					color: selectedColor,
					parentTagId: parseInt(parentTagId, 10) || null,
				},
			},
		});

		refetchTags();

		setTagName('');
	}, [refetchTags, createTag, tagName, selectedColor, parentTagId]);

	return (
		<Layout>
			<Layout.Header title='Tags' />
			<Layout.Body>
				<Box
					width='100%'
					border='500'
					borderColor='container.border.base'
					borderRadius='outer'
					bg='white'
					p='space.300'
					display='flex'
					flexDirection='column'
					rowGap='space.400'
				>
					{tags
						?.filter((tag) => !tag.parentTagId)
						.map((tag) => <TagTree key={tag.id} tag={tag} allTags={tags!} />)}
				</Box>
				<Box mt='space.400'>
					<Text as='div' fontSize='300' mb='space.300'>
						Create Tag
					</Text>
					<Box>
						<Box display='flex' flexDirection='column'>
							<Box display='flex' flexDirection='column' mb='space.300'>
								<Text fontSize='200' fontWeight='semibold' color='text.body' mr='space.300'>
									Name
								</Text>
								<Box width={300}>
									<Input
										id='newTagName'
										name='newTagName'
										value={tagName}
										onChange={onTagNameChange}
										mr='space.400'
									/>
								</Box>
							</Box>
							<Box display='flex' flexDirection='column' mb='space.300'>
								<Text fontSize='200' fontWeight='semibold' color='text.body'>
									Parent Tag
								</Text>
								<ListboxButton
									width={300}
									content={
										<Listbox value={String(parentTagId)} onChange={setParentTagId} width={400}>
											<Listbox.Group>
												<Listbox.Item key={0} value={'0'} children={<div>&nbsp;</div>} />
												{tags?.map((tag) => (
													<Listbox.Item key={tag.id} value={String(tag.id)}>
														<TagName id={tag.id} showIcon />
													</Listbox.Item>
												))}
											</Listbox.Group>
										</Listbox>
									}
								>
									<Box display='flex' alignItems='center'>
										{!parentTag && <div>&nbsp;</div>}
										{parentTag && (
											<>
												<TagName id={parentTag.id} showIcon />
											</>
										)}
									</Box>
								</ListboxButton>
							</Box>
							<Box display='flex' flexDirection='column' mb='space.300'>
								<Text fontSize='200' fontWeight='semibold' color='text.body'>
									Color
								</Text>
								<ListboxButton
									width={150}
									content={
										<Listbox value={selectedColor} onChange={setSelectedColor} width={200}>
											<Listbox.Group>
												{TAG_COLORS.map(({name, value}) => (
													<Listbox.Item
														key={value}
														value={value}
														elemBefore={<Icon name='tag' color={value} mr='space.300' />}
													>
														{name}
													</Listbox.Item>
												))}
											</Listbox.Group>
										</Listbox>
									}
								>
									<Box display='flex' alignItems='center'>
										<Icon name='tag' color={selectedColor} mr='space.300' />
										{TAG_COLORS.find(({value}) => value === selectedColor)?.name}
									</Box>
								</ListboxButton>
							</Box>
							<LoaderButton
								appearance='primary'
								width={100}
								isLoading={isCreating}
								onClick={onCreateTag}
							>
								Create
							</LoaderButton>
						</Box>
					</Box>
				</Box>
			</Layout.Body>
		</Layout>
	);
};

interface TagTreeProps {
	tag: Tag;
	allTags: Tag[];
	depth?: number;
}

const TagTree = ({tag, allTags, depth = 0}: TagTreeProps) => {
	return (
		<Box display='flex' flexDirection='column' ml={depth * 12} rowGap='space.200'>
			<TagToken id={tag.id} closeable={false} />
			{allTags
				.filter((childTag) => childTag.parentTagId === tag.id)
				.map((childTag) => (
					<TagTree key={childTag.id} tag={childTag} allTags={allTags} depth={depth + 1} />
				))}
		</Box>
	);
};

export default TagManagement;
