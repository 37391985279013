import {MOBILE_BREAKPOINT} from '../util/mobile';
// @ts-ignore
import LinkContainer from '@sproutsocial/racine/lib/Link/styles';
// @ts-ignore
import {Container as ModalContainer} from '@sproutsocial/racine/lib/Modal/styles';
import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
	html, body, #root {
		width: 100%;
		height: 100%;
	}

	body {
		margin: 0;
		font-family: ${({theme}) => theme.fontFamily};
		background: ${({theme}) => theme.colors.neutral[100]};
	}

	${LinkContainer} {
		text-decoration: none;
	}
	
	@media (width <= ${MOBILE_BREAKPOINT}px) {
		${ModalContainer} {
			max-width: 100%;
			max-height: 100%;
			width: 100%;
			height: 100%;
			border-radius: 0;
		}	
	}
`;

export default GlobalStyles;
