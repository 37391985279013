import {formatCurrency} from '../../formatters';
import {type Project, type Transaction} from '../../graphql';
import {calculateProjectProfit, formatProjectStatus} from '../../helpers/project';
import {List} from '../List';
import MobileFriendlyItemDisplay from '../MobileFriendlyItemDisplay';
import Table from '../Table';
import DollarAmount from '../visual/DollarAmount';
import {Link} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useMemo} from 'react';
import {formatShortDate, parseIsoDate} from 'shared/src/datetime';
import {orderBy} from 'shared/src/list';

type MinimumProject = Pick<Project, 'id' | 'name' | 'description' | 'status' | 'startDate'> & {
	customer?: Pick<Project['customer'], 'id' | 'name'>;
	transactions?: Array<Pick<Transaction, 'amount'>>;
};

interface ProjectsTableProps {
	projects: MinimumProject[];
	showCustomerName?: boolean;
	showProfit?: boolean;
}

const ProjectsTable = ({
	projects,
	showCustomerName = true,
	showProfit = false,
}: ProjectsTableProps) => {
	const sortedProjects = useMemo(() => orderBy(projects, 'startDate', 'desc'), [projects]);

	const onClickListItem = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
		window.location.assign(`/projects/${event.currentTarget.dataset['projectId']}`);
	}, []);

	return (
		<MobileFriendlyItemDisplay
			list={
				<List id='projects'>
					{projects.map((project) => (
						<List.Item
							key={project.id}
							title={project.name}
							description={project.description}
							data={formatCurrency(
								calculateProjectProfit({
									...project,
									transactions: project.transactions ?? [],
								}),
							)}
							data-project-id={project.id}
							onClick={onClickListItem}
						/>
					))}
				</List>
			}
			table={
				<Table
					id='projects'
					head={[
						{id: 'name', content: 'Name'},
						{id: 'description', content: 'Description'},
						{id: 'status', content: 'Status'},
						{id: 'startDate', content: 'Start Date'},
						...(showCustomerName ? [{id: 'customer', content: 'Customer'}] : []),
						...(showProfit ? [{id: 'profit', content: 'Profit'}] : []),
					]}
					items={sortedProjects}
					generateRow={(project) => ({
						id: String(project.id),
						cells: [
							<Link href={`/projects/${project.id}`}>{project.name}</Link>,
							project.description,
							formatProjectStatus(project.status),
							formatShortDate(parseIsoDate(project.startDate)),
							...(showCustomerName ? [project.customer?.name] : []),
							...(showProfit
								? [
										<DollarAmount
											value={calculateProjectProfit({
												...project,
												transactions: project.transactions ?? [],
											})}
										/>,
									]
								: []),
						],
					})}
				/>
			}
		/>
	);
};

export default ProjectsTable;
