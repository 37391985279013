import {formatCurrency} from '../../formatters';
import {Text, type TypeTextProps} from '@sproutsocial/racine';

type DollarAmountProps = Omit<TypeTextProps, 'children'> & {
	value: number;
	colored?: boolean;
	isBalanceDue?: boolean;
};

const DollarAmount = ({
	value,
	colored = true,
	isBalanceDue = false,
	...rest
}: DollarAmountProps) => {
	const props: any = {
		color: 'text.body',
		...rest,
	};

	if (colored) {
		if (isBalanceDue) {
			props.color = value <= 0 ? 'green.800' : 'red.800';
		} else if (value !== 0) {
			props.color = value >= 0 ? 'green.800' : 'red.800';
		}
	}

	return <Text {...props}>{isBalanceDue && value === 0 ? 'PAID' : formatCurrency(value)}</Text>;
};

export default DollarAmount;
