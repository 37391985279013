import {formatCurrency} from '../../formatters';
import {type InvoiceLineItem, InvoiceLineItemType} from '../../graphql';
import {getLineItemCost} from '../../invoice-helpers';
import {Box, Text} from '@sproutsocial/racine';
import styled from 'styled-components';

interface InvoiceLineItemPreviewProps {
	lineItem: InvoiceLineItem;
}

const InvoiceLineItemPreviewContainer = styled(Box)`
	border-top: 1px solid black;

	&:last-of-type {
		border-bottom: 1px solid black;
	}
`;

const InvoiceLineItemPreview = ({lineItem}: InvoiceLineItemPreviewProps) => {
	const isCredit =
		lineItem.type === InvoiceLineItemType.Credit || lineItem.type === InvoiceLineItemType.Payment;

	return (
		<InvoiceLineItemPreviewContainer display='flex' my='space.200'>
			<Box flex={5}>
				<Text fontSize='200'>{lineItem.name}</Text>
				{lineItem.description && (
					<Text fontSize='200' color='text.subtext'>
						<br />
						{lineItem.description}
					</Text>
				)}
			</Box>
			<Box flex={1}>{lineItem.quantity && <Text fontSize='200'>{lineItem.quantity}</Text>}</Box>
			<Box flex={1}>
				{lineItem.quantity && <Text fontSize='200'>{formatCurrency(lineItem.price)}</Text>}
			</Box>
			<Box flex={1}>
				<Text fontSize='200' fontWeight={isCredit ? 'bold' : 'normal'}>
					{isCredit && '('}
					{formatCurrency(getLineItemCost(lineItem))}
					{isCredit && ')'}
				</Text>
			</Box>
		</InvoiceLineItemPreviewContainer>
	);
};

export default InvoiceLineItemPreview;
