import deepEqual from 'deep-equal';
import Quill from 'quill';
import {type Delta} from 'quill/core';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import {useEffect, useLayoutEffect, useRef} from 'react';
import styled from 'styled-components';

export type QuillContent = Delta;

interface QuillEditorProps {
	content: QuillContent | undefined;
	onChange?: (content: QuillContent) => void;
	readOnly?: boolean;
}

const QuillEditor = ({content, onChange, readOnly}: QuillEditorProps) => {
	const quillRef = useRef<Quill | null>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const onTextChangeRef = useRef(onChange);

	useLayoutEffect(() => {
		onTextChangeRef.current = onChange;
	});

	useEffect(() => {
		const container = containerRef.current;
		const editorContainer = container!.appendChild(container!.ownerDocument.createElement('div'));

		const quill = new Quill(editorContainer, {
			theme: readOnly ? 'bubble' : 'snow',
			readOnly,
		});

		quillRef.current = quill;

		quill.on(Quill.events.TEXT_CHANGE, () => {
			onTextChangeRef.current?.(quill.getContents());
		});

		return () => {
			quillRef.current = null;

			container!.innerHTML = '';
		};
	}, [quillRef, readOnly]);

	useEffect(() => {
		if (content && !deepEqual(content, quillRef?.current?.getContents())) {
			quillRef?.current?.setContents(content, 'api');
		}
	}, [quillRef, content]);

	return <QuillStylesDiv ref={containerRef} />;
};

const QuillStylesDiv = styled.div`
	${({theme}) => `
		.ql-toolbar {
			border-top-left-radius: ${theme.radii.outer};
			border-top-right-radius: ${theme.radii.outer};
		}
		
		.ql-container {
			border-bottom-left-radius: ${theme.radii.outer};
			border-bottom-right-radius: ${theme.radii.outer};
		}
	`}
`;

QuillEditor.displayName = 'QuillEditor';

export default QuillEditor;
