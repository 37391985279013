import {DateTime} from 'luxon';

export const parseDatabaseDate = (date: Date): DateTime => {
	return DateTime.fromJSDate(date);
};

export const formatDatabaseDate = (date: DateTime): Date => {
	return date.toJSDate();
};

export const parseIsoDate = (date: string): DateTime => {
	return DateTime.fromFormat(date, 'yyyy-MM-dd');
};

export const formatIsoDate = (dateTime: DateTime): string => {
	return dateTime.toFormat('yyyy-MM-dd');
};

export const formatShortDate = (dateTime: DateTime): string => {
	return dateTime.toLocaleString(DateTime.DATE_SHORT);
};

export const formatFullDate = (dateTime: DateTime): string => {
	return dateTime.toLocaleString(DateTime.DATE_FULL);
};

export const getNameForDateRange = (startDate: DateTime, endDate: DateTime): string => {
	if (startDate.hasSame(endDate, 'day')) {
		return startDate.toFormat('MMMM d, yyyy');
	}

	if (
		startDate.hasSame(endDate, 'week', {useLocaleWeeks: true}) &&
		startDate.hasSame(startDate.startOf('week', {useLocaleWeeks: true}), 'day') &&
		endDate.hasSame(endDate.endOf('week', {useLocaleWeeks: true}), 'day')
	) {
		return `Week of ${startDate.toFormat('MMMM d, yyyy')}`;
	}

	if (
		startDate.hasSame(endDate, 'month') &&
		startDate.hasSame(startDate.startOf('month'), 'day') &&
		endDate.hasSame(endDate.endOf('month'), 'day')
	) {
		return startDate.toFormat('MMMM yyyy');
	}

	if (
		startDate.hasSame(endDate, 'year') &&
		startDate.hasSame(startDate.startOf('year'), 'day') &&
		endDate.hasSame(endDate.endOf('year'), 'day')
	) {
		return startDate.toFormat('yyyy');
	}

	return `${formatShortDate(startDate)} – ${formatShortDate(endDate)}`;
};
