import {ProjectStatus} from '../../graphql';
import {formatProjectStatus} from '../../helpers/project';
import {Select, type TypeSelectProps} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useId} from 'react';

type ProjectStatusPickerProps = Omit<
	TypeSelectProps,
	'id' | 'name' | 'value' | 'onChange' | 'children'
> & {
	id?: string;
	name?: string;
	status: ProjectStatus;
	onChange: (status: ProjectStatus) => void;
};

const ProjectStatusPicker = ({id, name, status, onChange, ...rest}: ProjectStatusPickerProps) => {
	const generatedId = useId();

	const onValueChange = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			onChange(event.currentTarget.value as ProjectStatus);
		},
		[onChange],
	);

	return (
		<Select
			{...rest}
			id={id ?? generatedId}
			name={name ?? generatedId}
			value={status}
			onChange={onValueChange}
		>
			<option value={ProjectStatus.Upcoming}>{formatProjectStatus(ProjectStatus.Upcoming)}</option>
			<option value={ProjectStatus.Planning}>{formatProjectStatus(ProjectStatus.Planning)}</option>
			<option value={ProjectStatus.InProgress}>
				{formatProjectStatus(ProjectStatus.InProgress)}
			</option>
			<option value={ProjectStatus.Done}>{formatProjectStatus(ProjectStatus.Done)}</option>
			<option value={ProjectStatus.Cancelled}>
				{formatProjectStatus(ProjectStatus.Cancelled)}
			</option>
			<option value={ProjectStatus.FollowUp}>{formatProjectStatus(ProjectStatus.FollowUp)}</option>
		</Select>
	);
};

export default ProjectStatusPicker;
