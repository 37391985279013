import {useSetState} from '../../hooks';
import ProjectPicker from './ProjectPicker';
import {Box, Button, Modal} from '@sproutsocial/racine';
import {useCallback} from 'react';

interface ProjectPickerModalProps {
	title: string;
	selectedProjectIds: Set<number>;
	onSave(selectedProjectIds: Set<number>): void;
	onCancel(): void;
}

const ProjectPickerModal = ({
	title,
	selectedProjectIds,
	onSave,
	onCancel,
}: ProjectPickerModalProps) => {
	const currentProjectIds = useSetState(selectedProjectIds);

	const handleCancel = useCallback(() => {
		onCancel();
	}, [onCancel]);

	const handleSave = useCallback(() => {
		onSave(new Set(currentProjectIds));
	}, [onSave, currentProjectIds]);

	const onProjectSelected = useCallback(
		(projectId: number) => {
			currentProjectIds.add(projectId);
		},
		[currentProjectIds],
	);

	const onProjectDeselected = useCallback(
		(projectId: number) => {
			currentProjectIds.delete(projectId);
		},
		[currentProjectIds],
	);

	return (
		<Modal isOpen>
			<Modal.Header title={title} />
			<Modal.Content>
				<ProjectPicker
					selectedProjectIds={currentProjectIds}
					onProjectDeselected={onProjectDeselected}
					onProjectSelected={onProjectSelected}
				/>
			</Modal.Content>
			<Modal.Footer>
				<Box display='flex' alignItems='center' justifyContent='flex-end'>
					<Button mr='space.300' onClick={handleCancel}>
						Cancel
					</Button>
					<Button appearance='primary' onClick={handleSave} width={100}>
						Save
					</Button>
				</Box>
			</Modal.Footer>
		</Modal>
	);
};

export default ProjectPickerModal;
